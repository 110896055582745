import {colors, createTheme} from "@mui/material";
import {grey} from "@mui/material/colors";

export const muiColorTheme = createTheme({
    palette: {
        primary: {
            main: colors.blue[800],
        },
        background: {
            default: grey[900],
            paper: grey[900],
        },
        mode: 'dark',
    },
});
